.content-container {
    display: flex;
    justify-content: center;
    margin-top: 4em;
    flex: 1;
    margin-bottom: 2.5em;
    align-items: center;
}

.buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
}

.original-image-class {
    min-height: 30vh;
    min-width: 30vw;
    height: auto
}


.to-right {
    transform: rotate(90deg) !important;
    display: block;
    overflow: auto !important
}


.marged-button {
    margin: 0px 0.6rem;
    font-size: 1.1rem;
    letter-spacing: 1px;
}

.rainbow-tabset {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    width: auto;
}

.rainbow-tabset_inner-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
    /* -webkit-justify-content: flex-start; */
    justify-content: center;
    /* width: 100%; center */
}

.filzxm {
    border-radius: .25rem;
}

.patients-container {
    padding: 0 30px;
}

.react-rainbow-admin-pages_container {
    padding: 16px 40px;
}

.react-rainbow-admin-pages_body {
    display: flex;
    flex-wrap: wrap;
}

.react-rainbow-admin-pages_card {
    width: 200px;
    font-size: 16px;
    font-weight: 500;
    color: #061c3f;
}

.react-rainbow-admin-pages_card-image {
    padding: 1rem;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-rainbow-admin-pages_anchor {
    margin-top: 24px;
    margin-right: 24px;
}

.react-rainbow-admin-pages_anchor:hover {
    text-decoration: none;
}

.react-rainbow-admin-pages_anchor:last-child {
    margin-right: 0;
}

@media (max-width: 600px) {

    .react-rainbow-admin-pages_anchor,
    .react-rainbow-admin-pages_card {
        width: 100%;
        margin-right: 0;
    }
}


.add-patient-button {
    margin: 20px 0px;
}

.react-rainbow-admin-users_header-container {
    padding: 16px 40px 0;
    margin-bottom: 16px;
}

.react-rainbow-admin-users_header {
    margin-top: 14px;
}

.react-rainbow-admin-users_cards-container {
    padding: 16px 40px 24px 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.react-rainbow-admin-users_card {
    width: 48%;
    padding-bottom: 16px;
}

.react-rainbow-admin-users_chart {
    margin: 0 20px;
}

.react-rainbow-admin-users_card-title {
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #a4a7b5;
    text-transform: uppercase;
    margin-bottom: 8px;
    text-align: center;
}

.react-rainbow-admin-users_tab-set {
    padding-left: 40px;
    background-color: #f4f6f9;
}

.react-rainbow-admin-users_tab-content {
    height: max-content;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 0.25rem;
    max-width: 80vw;
    width: auto;
}

.react-rainbow-admin-users_current-status {
    display: flex;
    align-items: center;
}

.react-rainbow-admin-users_current-status_value {
    margin-left: 8px;
}

.react-rainbow-admin-users_table-edit-icon {
    border: 1px solid #e3e5ed;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-rainbow-admin-users_badge {
    background-color: #1de9b6;
}

.react-rainbow-admin-users_user-id-cell-container {
    display: flex;
    align-items: center;
}

.rainbow-table_cell-content {
    border: 1px solid transparent;
    color: #576574;
    font-size: 0.875rem;
    height: auto;
    line-height: 50px;
    padding: 0 0.5rem;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.react-rainbow-admin-users_user-id-cell {
    margin-left: 8px;
    font-weight: 400;
}

.react-rainbow-admin-users_user-id-cell-container:hover {
    color: #01b6f5;
}

@media (max-width: 800px) {
    .react-rainbow-admin-users_header-container {
        padding: 16px;
        margin-bottom: 16px;
    }

    .react-rainbow-admin-users_cards-container {
        flex-direction: column;
    }

    .react-rainbow-admin-users_card {
        width: 100%;
        margin-bottom: 16px;
    }

    .react-rainbow-admin-users_tab-set {
        padding-left: 24px;
    }

    .react-rainbow-admin-pages_container {
        padding: 16px;
    }

    .react-rainbow-admin-pages_anchor,
    .react-rainbow-admin-pages_card {
        margin-right: 8px;
    }

    .react-rainbow-admin-pages_card-image {
        height: 8rem;
    }

    .rainbow-tabset_container {
        width: max-content;
    }

    .react-rainbow-admin-users_tab-content {
        max-width: unset;
        width: 100%;
    }
    .subcontent-container {
        width: auto;
        /* width: 100%; */
    }
}