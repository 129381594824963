.jgSBRJ {
    text-transform: none;
}

.StripeElement {
    margin: 1.5rem auto;
    border: 1px solid #a4a7b5;
    border-radius: 12rem;
    padding: 0.75rem;
}

.checkout-telec-submit {
    display: block;
    margin: 1rem auto;
    width:  30%;
}


.phone-validation-message {
    color: #009acf;
    font-weight: bolder;
    margin: 1rem;
    margin-top: 0;
    font-size: 0.8rem;
}

.phone-validation-form {
    padding: 30px;
    line-height: normal;
}

.PhoneInputInput  {
    max-width: 30%;
}

.telecav-notice-elem {
    display: list-item;
    color: #009acf;
    margin-left: 1rem;
    margin-bottom: 5px;
  }
  
  .telecav-notice-elem > p {
    color: #3e3e3c;
  }


.add-telec-phone-step:hover { 
box-shadow: none;
}


.payment-card {
    box-shadow: none;
}

.payment-card:hover { 
    box-shadow: none;
}

.add-telec-modal {
    flex: 1;
    max-width: 80vw;
    width: inherit !important;
}

.requirements-list { 
    display: flex;
    flex-direction: column;
}
.checkout-telec-form {
    flex: 1;
    margin: auto 6rem;
}

.rainbow-time-picker_footer {
    width: 100%;
    display: flex;
    align-items: center;
}

.rainbow-radio-group_inner-container {
    display: flex;
    flex-direction: column;
}

.contacts-container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    flex: 1;
    margin: auto 2rem;
    align-items: center;
}


.PhoneInputCountryIconImg {
 vertical-align: unset !important;
 line-height: normal !important;
}

.starting-today-card {
    padding: 1rem;
    font-size: 1rem;
    border: none;
    border-radius: .25rem;
}

.starting-today-card > div > header > h2 > a > span {
    font-weight: 600;
    letter-spacing: 1px;
}

.starting-today-card > div > header > h2 > a  {
    text-decoration: none;
    cursor: default;
}

.starting-today-card > div {
margin-top: 0;
}
