.dialogSpinner-container {
    display: flex;
    justify-content: center;
    min-height: 35vh;
    margin-top: 2rem;
}


.dialogSpinner-text {
    color: #03b2cb;
    font-size: 0.85rem;
}