.signup-container {
  display: flex;
  flex-wrap: wrap;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  flex: 0;
  max-width: 60rem;
  flex: 1;
}


.control-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-wrap: wrap;
}


.bg-container-sig {
  background-color: #86bedd;
  display: flex;
  flex: 1;
}

.img-kat {
  width: 100%;
  margin-bottom: 20px;
}

.signup-submit-button {
  display: block;
  margin: auto;
  letter-spacing: 1px;
  font-size: 1.2rem;
}

.text-kat {
  color: #1a90bf;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.00938em;
  text-transform: uppercase;
}