.login-container {
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
}



.card-container-login {
  display: flex;
  flex-wrap: wrap;
  max-width: 60rem;
  flex: 1 1;
  justify-content: center;
}

.card-login {
  align-self: center;
  padding: 60px;
  border: none;
  box-shadow: none;
}

.card-login:hover {
  box-shadow: none;
}

.login-icon-v2 {
  padding-right : 10px;
  padding-left : 10px;
}

.login-field {
  flex: 1;
  margin: 1rem auto;
}

.article-form {
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: space-between;
  padding: 50;
}

.login-form {
  padding: 30px;
}

.bg-container-login {
  background-color: #86bedd;
  display: flex;
  flex: 1;
}


.react-rainbow-admin-forms_card {
  width: 50%;
  flex-wrap: wrap;
  position: absolute;
  margin: auto;
  align-self: center;
  margin-right: 48px;
  margin-left: 28px;
  opacity: 0.9;
  border: none;
}

.img-kat {
  width: 100%;
  margin-bottom: 20px;
}

.text-kat {
  color: #1a90bf;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.00938em;
  text-transform: uppercase;
}

.second-division {
  margin: 10rem auto;
  padding: 40px;
  max-width: 100%;
  flex: 1;
  position: fixed;
}

.sc-gZMcBi {
  margin-top: 2rem;
}

.bg-container1 {
  background-color: white;
  width: 55%;
  display: flex;
}

.react-rainbow-admin-forms_card:not(:last-child) {
  margin-right: 48px;
  margin-left: 28px;
}

.react-rainbow-admin-forms_header {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.react-rainbow-admin-forms_logo {
  width: 140px;
  height: 50px;
  margin-bottom: 15px;
}

.rainbow-rainbow-forms_forgot {
  text-align: center;
  color: #01b6f5;
  font-size: 1rem;
}
