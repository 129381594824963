.react-rainbow-admin-messages {
    display: flex;
    min-height: calc(100vh - 142px);
}

.react-rainbow-admin-messages_contacts-container {
    width: auto;
    background-color: #f4f6f9;
}

.react-rainbow-admin-messages_contacts-search {
    padding: 0.75rem 0.625rem;
    border-bottom: 1px solid #eceef6;
}

.react-rainbow-admin-messages_contacts {
    padding: 0 0.625rem;
}

.react-rainbow-admin-messages_contact {
    display: flex;
    padding: 0.5rem;
    margin: 0.75rem 0;
    width: 100%;
    height: 3.25rem;
    border-radius: 100px;
    cursor: pointer;
}

.react-rainbow-admin-messages_contact:hover {
    background-color: #eceef6;
}

.react-rainbow-admin-messages_contact--active {
    background-color: #eceef6;
}

.react-rainbow-admin-messages_contact-avatar {
    flex-shrink: 0;
}

.react-rainbow-admin-messages_contact--online {
    width: 5px;
    height: 5px;
    background-color: #1de9b6;
    border-radius: 100%;
    position: absolute;
    margin-top: 18px;
}

.react-rainbow-admin-messages_contact-content {
    width: 88%;
    margin-left: 0.75rem;
    pointer-events: none;
}

.react-rainbow-admin-messages_contact-name {
    font-size: 0.875rem;
    line-height: 1.29;
    letter-spacing: 0.5px;
    color: #061c3f;
}

.react-rainbow-admin-messages_contact-time {
    font-size: 0.75rem;
    display: flex;
    justify-content: flex-end;
    letter-spacing: 0.5px;
    text-align: right;
    color: #a4a7b5;
}

.react-rainbow-admin-messages_contact-message {
    line-height: 1.4;
    color: #576574;
    font-size: 0.625rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0rem 0.3rem;
}

.react-rainbow-admin-messages_body {
    width: auto;
    max-height: 84vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-color: #eff1f7;
    padding: 1rem 2.25rem;
    position: relative;
    flex: 1;
}

.react-rainbow-admin-messages_input-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border-radius: 20px;
    padding: 0.5rem;
}

.react-rainbow-admin-messages_input-options {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0 1rem;
}

.react-rainbow-admin-messages_body-messages {
    margin-bottom: 3rem;
    margin-top: 5rem;
    width: 100%; 
    max-height: 60vh;
    overflow-y: auto;
    height: 100%;
    padding: 0.65rem;
}

.react-rainbow-admin-messages_message {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    position: relative;
}

.react-rainbow-admin-messages_message:not(:first-child) {
    margin-top: 2rem;
}

.react-rainbow-admin-messages_message-avatar {
    flex-shrink: 0;
}

.react-rainbow-admin-messages_message-text-contact {
    display: flex;
    align-items: flex-start;
    border-radius: 0.625rem;
    padding: 0.625rem;
    font-size: 1rem;
    margin: -0.625rem 0 0.25rem 0.75rem;
    background-color: #01b6f5;
    color: #fff;
}

.react-rainbow-admin-messages_message-text-contact:before {
    width: 0;
    height: 0;
    content:"";
    top:0.875rem;
    right: 1.375rem;
    margin-right: -0.875rem;
    position:relative;
    border-style: solid;
    border-width: 0.75rem 1rem 0 0;
    border-color: transparent #01b6f5 transparent transparent;
    transform: rotateZ(-20deg);
}

.react-rainbow-admin-messages_message-text-user {
    display: flex;
    align-items: flex-start;
    border-radius: 0.625rem;
    padding: 0.625rem;
    margin: -0.625rem 0.75rem 0.25rem 0;
    font-size: 1rem;
    background-color: #fff;
}

.react-rainbow-admin-messages_message-text-user:after {
    width: 0;
    height: 0;
    content:"";
    top:0.875rem;
    left: 1.375rem;
    margin-left: -0.875rem;
    position:relative;
    border-style: solid;
    border-width: 0 1rem 0.75rem 0;
    border-color: transparent transparent #fff transparent;
    transform: rotateZ(20deg);
}

.react-rainbow-admin-messages_message--sent {
    position: absolute;
    right: 3.15rem;
    bottom: -0.875rem;
    display: flex;
    align-items: center;
}

.react-rainbow-admin-messages_message--sent svg {
    margin-left: 0.25rem;
}

.react-rainbow-admin-messages_message--sent-date {
    position: absolute;
    left: 3.75rem;
    bottom: -0.875rem;
}

.react-rainbow-admin-messages_message--checked g {
    fill: #01b6f5;
    stroke: #01b6f5;
}

.react-rainbow-admin-messages_input .rainbow-input_icon-container .rainbow-input_icon {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 100%;
    background-color: #01b6f5;
    margin-top: 0.25rem;
    margin-right: -0.25rem;
}

.react-rainbow-admin-messages_body-selected-contact {
    position: absolute;
    top: 1rem;
    left: 2.25rem;
    display: flex;
}

.react-rainbow-admin-messages_body--divider {
    width: 100%;
    border-bottom: 1px solid #e6e9ef;
    position: absolute;
    top: 4rem;
}

.react-rainbow-admin-messages_body-selected-contact-content {
    margin-left: 0.75rem;
    pointer-events: none;
}

.react-rainbow-admin-messages_body-selected-contact-name {
    font-size: 0.875rem;
    line-height: 1.29;
    letter-spacing: 0.5px;
    color: #061c3f;
}

.react-rainbow-admin-messages_body-selected-contact--status {
    line-height: 1.4;
    color: #576574;
    font-size: 0.9rem;
}

.unseen-message { 
    background-color: #a0dafd69;
}

.unseen-indicator {
    margin-left: 8px;
    width: 0.6125rem;
    height: 0.6125rem;
    background-color: #ea4243;
    border-radius: 25px;
}


@media (max-width: 800px) {
    .react-rainbow-admin-messages_body {
        min-width: 150vw;
    }
    .react-rainbow-admin-messages_contacts-container {
        max-width: unset;
    }

    #tabset-chat .hRyptZ {
        flex-wrap: wrap-reverse;
    }
}