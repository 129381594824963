.react-rainbow-admin-tile {
    width: 100%;
}

.react-rainbow-admin-tile:not(:last-child) {
    margin-right: 24px;
}

.react-rainbow-admin-tile_title {
    letter-spacing: 0.3px;
    color: #a4a7b5;
}

.react-rainbow-admin-tile_title--inverse {
    color: #e3e5ed;
}

.react-rainbow-admin-tile_content {
    font-size: 1rem;
    line-height: 1.07;
    letter-spacing: 1.8px;
    color: #061c3f;
    
}

.react-rainbow-admin-tile_content--inverse {
    color: #FFF;
}

@media (max-width: 800px) {
    .react-rainbow-admin-tile:not(:last-child) {
        margin: 0 0 8px 0;
    }
}

.react-rainbow-admin-dashboard_view-port {
    padding: 16px 40px 24px 40px;
}

.align-stretch-center {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: stretch;
}

.react-rainbow-admin-dashboard_card {
    width: 100%;
    flex-wrap: wrap;
}

.react-rainbow-admin-dashboard_card:not(:last-child) {
    margin-right: 24px;
}

.react-rainbow-admin-dashboard_card--content {
    font-size: 28px;
    line-height: 1.07;
    letter-spacing: 1.8px;
}

.react-rainbow-admin-dashboard_badge--success {
    background-color: #1de9b6 !important;
    align-self: flex-start;
}

.react-rainbow-admin-dashboard_badge--error {
    background-color: #fe4849 !important;
    align-self: flex-start;
}

.react-rainbow-admin-dashboard_chart-title {
    letter-spacing: 0.2px;
    text-transform: uppercase;
}

.react-rainbow-admin-dashboard_avatar {
    flex-shrink: 0;
}

.react-rainbow-admin-dashboard_divider {
    border-bottom: solid 1px #e3e5ed;
}

.react-rainbow-admin-dashboard_list-card--footer-link {
    font-size: 13px;
}

.react-rainbow-admin-dashboard_list-card--footer-link:hover,
.react-rainbow-admin-dashboard_list-card--footer-link:focus,
.react-rainbow-admin-dashboard_list-card--footer-link:active,
.react-rainbow-admin-dashboard_list-card--footer-link:visited {
    color: #009acf;
}

.react-rainbow-admin-dashboard_card-users--user {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #fe4849;
    margin-right: 4px;
    margin-bottom: 4px;
}

.react-rainbow-admin-dashboard_card-users--user-active {
    background-color: #1de9b6;
}

.react-rainbow-admin-dashboard_card-message--message-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.react-rainbow-admin-dashboard_tile-icon {
    height: 37px;
    width: 37px;
}


.dashboard-card {
  
}


.dashboard-card-header {
    width: 100%;
    margin-bottom: 1rem;
    /* flex-grow: 1; */
    display: flex;
    justify-content: center;
    margin: 10px auto;
    font-size: 1rem;
    /* background-color: #01b8f7; */
    margin-top: 0;
    color: white;
    font-weight: 400;
    padding: 1rem;
    letter-spacing: 2px;
}


@media (max-width: 800px) {
    .react-rainbow-admin-dashboard_view-port {
        padding: 16px 16px 24px 16px;
        max-width: 100vw;
    }

    .react-rainbow-admin-dashboard_section {
        flex-direction: column;
    }

    .react-rainbow-admin-dashboard_card:not(:last-child) {
        margin: 0 0 8px 0;
    }
}

/* REVIEW delete this if icon size is big */
.smallIcon .jOpkFC {
    width: 40px;
    height: 40px;
}

.smallIcon .clUSXC {
    width: 40px;
    height: 40px;
}
.container {
    position: relative;
    min-width: 70vw;

}

.rainbow-rainbow-forms_inputs-field {
    flex: 1 1;
    margin: 1em;
}

.hTXljT { 
    text-transform: none;
}


.form {
    width: 100%;
    flex-wrap: wrap;
    /* position: absolute; */
    margin-top: 4%;
    margin-bottom: 4%;
}

.react-rainbow-admin-forms_card:not(:last-child) {
    margin-right: 48px;
}

.react-rainbow-admin-forms_header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.react-rainbow-admin-forms_header h1 {
    font-size: 1.2rem;
    color: #061c3f;
}

.react-rainbow-admin-forms_header p {
    font-weight: 300;
    color: #576574;
    line-height: 1.71;
}

.react-rainbow-admin-forms_header p:nth-child(2) {
    font-weight: normal;
    color: #01b6f5;
    margin-left: 3px;
    cursor: pointer;
}

.rainbow-rainbow-forms_inputs-field {
    margin: 1rem;
}

.react-rainbow-admin-forms_logo {
    width: 140px;
    height: 50px;
    margin-bottom: 15px;
}

.rainbow-rainbow-forms_forgot {
    text-align: center;
    color: #01b6f5;
    font-size: 1rem;
}


.margin-datepicker {
    margin: 1rem;
}

@media (max-width: 800px) {
    .react-rainbow-admin-forms_container {
        padding: 16px;
    }

    .react-rainbow-admin-forms_section {
        flex-direction: column;
        padding-top: 0;
    }

    .rainbow-rainbow-forms_button {
        width: 100%;

        display: flex;
        justify-content: center;
    }

    .react-rainbow-admin-forms_section:last-child {
        width: 100%;
    }

    .react-rainbow-admin-forms_card {
        margin-top: 13px;
    }


    .rainbow-rainbow-forms_inputs-field {
        margin: 0 0 18px 0 !important;
    }

    .margin-datepicker {
        margin: 1rem auto;
    }
}
.content-container {
    display: flex;
    justify-content: center;
    margin-top: 4em;
    flex: 1 1;
    margin-bottom: 2.5em;
    align-items: center;
}

.buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
}

.original-image-class {
    min-height: 30vh;
    min-width: 30vw;
    height: auto
}


.to-right {
    -webkit-transform: rotate(90deg) !important;
            transform: rotate(90deg) !important;
    display: block;
    overflow: auto !important
}


.marged-button {
    margin: 0px 0.6rem;
    font-size: 1.1rem;
    letter-spacing: 1px;
}

.rainbow-tabset {
    display: flex;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
    justify-content: flex-start;
    width: auto;
}

.rainbow-tabset_inner-container {
    display: flex;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
    /* -webkit-justify-content: flex-start; */
    justify-content: center;
    /* width: 100%; center */
}

.filzxm {
    border-radius: .25rem;
}

.patients-container {
    padding: 0 30px;
}

.react-rainbow-admin-pages_container {
    padding: 16px 40px;
}

.react-rainbow-admin-pages_body {
    display: flex;
    flex-wrap: wrap;
}

.react-rainbow-admin-pages_card {
    width: 200px;
    font-size: 16px;
    font-weight: 500;
    color: #061c3f;
}

.react-rainbow-admin-pages_card-image {
    padding: 1rem;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-rainbow-admin-pages_anchor {
    margin-top: 24px;
    margin-right: 24px;
}

.react-rainbow-admin-pages_anchor:hover {
    text-decoration: none;
}

.react-rainbow-admin-pages_anchor:last-child {
    margin-right: 0;
}

@media (max-width: 600px) {

    .react-rainbow-admin-pages_anchor,
    .react-rainbow-admin-pages_card {
        width: 100%;
        margin-right: 0;
    }
}


.add-patient-button {
    margin: 20px 0px;
}

.react-rainbow-admin-users_header-container {
    padding: 16px 40px 0;
    margin-bottom: 16px;
}

.react-rainbow-admin-users_header {
    margin-top: 14px;
}

.react-rainbow-admin-users_cards-container {
    padding: 16px 40px 24px 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.react-rainbow-admin-users_card {
    width: 48%;
    padding-bottom: 16px;
}

.react-rainbow-admin-users_chart {
    margin: 0 20px;
}

.react-rainbow-admin-users_card-title {
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #a4a7b5;
    text-transform: uppercase;
    margin-bottom: 8px;
    text-align: center;
}

.react-rainbow-admin-users_tab-set {
    padding-left: 40px;
    background-color: #f4f6f9;
}

.react-rainbow-admin-users_tab-content {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 0.25rem;
    max-width: 80vw;
    width: auto;
}

.react-rainbow-admin-users_current-status {
    display: flex;
    align-items: center;
}

.react-rainbow-admin-users_current-status_value {
    margin-left: 8px;
}

.react-rainbow-admin-users_table-edit-icon {
    border: 1px solid #e3e5ed;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-rainbow-admin-users_badge {
    background-color: #1de9b6;
}

.react-rainbow-admin-users_user-id-cell-container {
    display: flex;
    align-items: center;
}

.rainbow-table_cell-content {
    border: 1px solid transparent;
    color: #576574;
    font-size: 0.875rem;
    height: auto;
    line-height: 50px;
    padding: 0 0.5rem;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.react-rainbow-admin-users_user-id-cell {
    margin-left: 8px;
    font-weight: 400;
}

.react-rainbow-admin-users_user-id-cell-container:hover {
    color: #01b6f5;
}

@media (max-width: 800px) {
    .react-rainbow-admin-users_header-container {
        padding: 16px;
        margin-bottom: 16px;
    }

    .react-rainbow-admin-users_cards-container {
        flex-direction: column;
    }

    .react-rainbow-admin-users_card {
        width: 100%;
        margin-bottom: 16px;
    }

    .react-rainbow-admin-users_tab-set {
        padding-left: 24px;
    }

    .react-rainbow-admin-pages_container {
        padding: 16px;
    }

    .react-rainbow-admin-pages_anchor,
    .react-rainbow-admin-pages_card {
        margin-right: 8px;
    }

    .react-rainbow-admin-pages_card-image {
        height: 8rem;
    }

    .rainbow-tabset_container {
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
    }

    .react-rainbow-admin-users_tab-content {
        max-width: unset;
        width: 100%;
    }
    .subcontent-container {
        width: auto;
        /* width: 100%; */
    }
}
.react-rainbow-admin-table-pagination {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-top: 1px solid #e3e5ed;
    border-bottom: 1px solid #e3e5ed;
    margin-bottom: 24px;
}

.accordion-container {
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
  margin: auto;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  margin-bottom: 1em;
}

.flex-grow {
  flex: 1 1;
}

.rainbow-radio-group_inner-container {
  display: flex;
}

.rainbow-radio-group_radio .rainbow-radio-group_radio-faux {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  border: 1px solid #01b6f5;
  border-radius: 12rem;
  background-color: white;
  -webkit-transition: border 0.1s linear, background-color 0.1s linear;
  transition: border 0.1s linear, background-color 0.1s linear;
  margin: auto 15px;
}

.button-add {
  margin: auto;
  margin-top: 0.4em;
  width: 30%;
}

.react-rainbow-admin-forms_card:not(:last-child) {
  margin-right: 48px;
}

.react-rainbow-admin-forms_header {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.react-rainbow-admin-forms_logo {
  width: 140px;
  height: 50px;
  margin-bottom: 15px;
}

.rainbow-rainbow-forms_inputs-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
}

.rainbow-rainbow-forms_forgot {
  text-align: center;
  color: #01b6f5;
  font-size: 1rem;
}

@media (max-width: 800px) {
  .react-rainbow-admin-forms_container {
    padding: 16px;
  }

  .react-rainbow-admin-forms_section {
    flex-direction: column;
    padding-top: 0;
  }

  .rainbow-rainbow-forms_button {
    width: 100%;

    display: flex;
    justify-content: center;
  }

  .react-rainbow-admin-forms_section:last-child {
    width: 100%;
  }

  .react-rainbow-admin-forms_card {
    margin-top: 13px;
  }

  .rainbow-rainbow-forms_inputs-container > .rainbow-flex {
    flex-direction: column;
  }

  .rainbow-rainbow-forms_inputs-field {
    margin: 0 0 18px 0 !important;
  }
  
}


.original-image-class {
    min-height: 30vh;
    min-width: 30vw;
    height: auto
}


.to-right {
    -webkit-transform: rotate(90deg) !important;
            transform: rotate(90deg) !important;
    display: block;
    overflow: auto !important
}


.image-input {
    cursor: pointer;
    border-radius: 100px;
    font-size: 1rem;
    margin-top: 1rem;
    text-decoration: none;
    line-height: 2.375rem;
    align-self: center;
    background-color: #009acf;
    border-color: #009acf;
    color: white;
    border: 1px solid #01b6f5;
    padding: 0 1rem;
    text-align: center;
    vertical-align: middle;
    -webkit-transition: border 0.15s linear;
    transition: border 0.15s linear;

}

.rotate-0 .image-gallery-image {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

.rotate-90 .image-gallery-image {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.rotate-180 .image-gallery-image {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.rotate-270 .image-gallery-image {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}

.image-gallery-slide {
    background-color: inherit;
    position: absolute;
    height: auto;
    top: inherit;
    left: inherit;
}
.react-rainbow-admin-messages {
    display: flex;
    min-height: calc(100vh - 142px);
}

.react-rainbow-admin-messages_contacts-container {
    width: auto;
    background-color: #f4f6f9;
}

.react-rainbow-admin-messages_contacts-search {
    padding: 0.75rem 0.625rem;
    border-bottom: 1px solid #eceef6;
}

.react-rainbow-admin-messages_contacts {
    padding: 0 0.625rem;
}

.react-rainbow-admin-messages_contact {
    display: flex;
    padding: 0.5rem;
    margin: 0.75rem 0;
    width: 100%;
    height: 3.25rem;
    border-radius: 100px;
    cursor: pointer;
}

.react-rainbow-admin-messages_contact:hover {
    background-color: #eceef6;
}

.react-rainbow-admin-messages_contact--active {
    background-color: #eceef6;
}

.react-rainbow-admin-messages_contact-avatar {
    flex-shrink: 0;
}

.react-rainbow-admin-messages_contact--online {
    width: 5px;
    height: 5px;
    background-color: #1de9b6;
    border-radius: 100%;
    position: absolute;
    margin-top: 18px;
}

.react-rainbow-admin-messages_contact-content {
    width: 88%;
    margin-left: 0.75rem;
    pointer-events: none;
}

.react-rainbow-admin-messages_contact-name {
    font-size: 0.875rem;
    line-height: 1.29;
    letter-spacing: 0.5px;
    color: #061c3f;
}

.react-rainbow-admin-messages_contact-time {
    font-size: 0.75rem;
    display: flex;
    justify-content: flex-end;
    letter-spacing: 0.5px;
    text-align: right;
    color: #a4a7b5;
}

.react-rainbow-admin-messages_contact-message {
    line-height: 1.4;
    color: #576574;
    font-size: 0.625rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0rem 0.3rem;
}

.react-rainbow-admin-messages_body {
    width: auto;
    max-height: 84vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-color: #eff1f7;
    padding: 1rem 2.25rem;
    position: relative;
    flex: 1 1;
}

.react-rainbow-admin-messages_input-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border-radius: 20px;
    padding: 0.5rem;
}

.react-rainbow-admin-messages_input-options {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0 1rem;
}

.react-rainbow-admin-messages_body-messages {
    margin-bottom: 3rem;
    margin-top: 5rem;
    width: 100%; 
    max-height: 60vh;
    overflow-y: auto;
    height: 100%;
    padding: 0.65rem;
}

.react-rainbow-admin-messages_message {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    position: relative;
}

.react-rainbow-admin-messages_message:not(:first-child) {
    margin-top: 2rem;
}

.react-rainbow-admin-messages_message-avatar {
    flex-shrink: 0;
}

.react-rainbow-admin-messages_message-text-contact {
    display: flex;
    align-items: flex-start;
    border-radius: 0.625rem;
    padding: 0.625rem;
    font-size: 1rem;
    margin: -0.625rem 0 0.25rem 0.75rem;
    background-color: #01b6f5;
    color: #fff;
}

.react-rainbow-admin-messages_message-text-contact:before {
    width: 0;
    height: 0;
    content:"";
    top:0.875rem;
    right: 1.375rem;
    margin-right: -0.875rem;
    position:relative;
    border-style: solid;
    border-width: 0.75rem 1rem 0 0;
    border-color: transparent #01b6f5 transparent transparent;
    -webkit-transform: rotateZ(-20deg);
            transform: rotateZ(-20deg);
}

.react-rainbow-admin-messages_message-text-user {
    display: flex;
    align-items: flex-start;
    border-radius: 0.625rem;
    padding: 0.625rem;
    margin: -0.625rem 0.75rem 0.25rem 0;
    font-size: 1rem;
    background-color: #fff;
}

.react-rainbow-admin-messages_message-text-user:after {
    width: 0;
    height: 0;
    content:"";
    top:0.875rem;
    left: 1.375rem;
    margin-left: -0.875rem;
    position:relative;
    border-style: solid;
    border-width: 0 1rem 0.75rem 0;
    border-color: transparent transparent #fff transparent;
    -webkit-transform: rotateZ(20deg);
            transform: rotateZ(20deg);
}

.react-rainbow-admin-messages_message--sent {
    position: absolute;
    right: 3.15rem;
    bottom: -0.875rem;
    display: flex;
    align-items: center;
}

.react-rainbow-admin-messages_message--sent svg {
    margin-left: 0.25rem;
}

.react-rainbow-admin-messages_message--sent-date {
    position: absolute;
    left: 3.75rem;
    bottom: -0.875rem;
}

.react-rainbow-admin-messages_message--checked g {
    fill: #01b6f5;
    stroke: #01b6f5;
}

.react-rainbow-admin-messages_input .rainbow-input_icon-container .rainbow-input_icon {
    width: 1.875rem;
    height: 1.875rem;
    border-radius: 100%;
    background-color: #01b6f5;
    margin-top: 0.25rem;
    margin-right: -0.25rem;
}

.react-rainbow-admin-messages_body-selected-contact {
    position: absolute;
    top: 1rem;
    left: 2.25rem;
    display: flex;
}

.react-rainbow-admin-messages_body--divider {
    width: 100%;
    border-bottom: 1px solid #e6e9ef;
    position: absolute;
    top: 4rem;
}

.react-rainbow-admin-messages_body-selected-contact-content {
    margin-left: 0.75rem;
    pointer-events: none;
}

.react-rainbow-admin-messages_body-selected-contact-name {
    font-size: 0.875rem;
    line-height: 1.29;
    letter-spacing: 0.5px;
    color: #061c3f;
}

.react-rainbow-admin-messages_body-selected-contact--status {
    line-height: 1.4;
    color: #576574;
    font-size: 0.9rem;
}

.unseen-message { 
    background-color: #a0dafd69;
}

.unseen-indicator {
    margin-left: 8px;
    width: 0.6125rem;
    height: 0.6125rem;
    background-color: #ea4243;
    border-radius: 25px;
}


@media (max-width: 800px) {
    .react-rainbow-admin-messages_body {
        min-width: 150vw;
    }
    .react-rainbow-admin-messages_contacts-container {
        max-width: unset;
    }

    #tabset-chat .hRyptZ {
        flex-wrap: wrap-reverse;
    }
}
.login-container {
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
}



.card-container-login {
  display: flex;
  flex-wrap: wrap;
  max-width: 60rem;
  flex: 1 1;
  justify-content: center;
}

.card-login {
  align-self: center;
  padding: 60px;
  border: none;
  box-shadow: none;
}

.card-login:hover {
  box-shadow: none;
}

.login-icon-v2 {
  padding-right : 10px;
  padding-left : 10px;
}

.login-field {
  flex: 1 1;
  margin: 1rem auto;
}

.article-form {
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: space-between;
  padding: 50;
}

.login-form {
  padding: 30px;
}

.bg-container-login {
  background-color: #86bedd;
  display: flex;
  flex: 1 1;
}


.react-rainbow-admin-forms_card {
  width: 50%;
  flex-wrap: wrap;
  position: absolute;
  margin: auto;
  align-self: center;
  margin-right: 48px;
  margin-left: 28px;
  opacity: 0.9;
  border: none;
}

.img-kat {
  width: 100%;
  margin-bottom: 20px;
}

.text-kat {
  color: #1a90bf;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.00938em;
  text-transform: uppercase;
}

.second-division {
  margin: 10rem auto;
  padding: 40px;
  max-width: 100%;
  flex: 1 1;
  position: fixed;
}

.sc-gZMcBi {
  margin-top: 2rem;
}

.bg-container1 {
  background-color: white;
  width: 55%;
  display: flex;
}

.react-rainbow-admin-forms_card:not(:last-child) {
  margin-right: 48px;
  margin-left: 28px;
}

.react-rainbow-admin-forms_header {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.react-rainbow-admin-forms_logo {
  width: 140px;
  height: 50px;
  margin-bottom: 15px;
}

.rainbow-rainbow-forms_forgot {
  text-align: center;
  color: #01b6f5;
  font-size: 1rem;
}

.signup-container {
  display: flex;
  flex-wrap: wrap;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  flex: 0 1;
  max-width: 60rem;
  flex: 1 1;
}


.control-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-wrap: wrap;
}


.bg-container-sig {
  background-color: #86bedd;
  display: flex;
  flex: 1 1;
}

.img-kat {
  width: 100%;
  margin-bottom: 20px;
}

.signup-submit-button {
  display: block;
  margin: auto;
  letter-spacing: 1px;
  font-size: 1.2rem;
}

.text-kat {
  color: #1a90bf;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.75;
  letter-spacing: 0.00938em;
  text-transform: uppercase;
}
.add-index-c-button {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    align-self: end;
    margin: auto;
}

.wrapflex { 
    flex-wrap: wrap;
}

.card-cpurpose {
    opacity: 1;
    padding: 1.5rem;
}

.submit-buttons-cpurpose {
    justify-content: space-around;
}
.add-purpose-button { 
    margin: 20px 0;
    border-radius: .25rem;
}

.react-rainbow-admin-user-cell_container {
    display: flex;
    align-items: center;
}

.react-rainbow-admin-user-cell_name {
    margin-left: 8px;
    font-weight: 400;
}

.react-rainbow-admin-user-cell_container:hover {
    color: #01b6f5;
}

.button-action {
    margin-top: 5px;
    font-size: 0.8rem;
    line-height: 1.875rem;
}

.actions-container {
    display: flex;
    justify-content: flex-end;
}
.custom-input-add-file {
  border-radius: 100px;
  line-height: 2rem;
  background-clip: border-box;
  font-size: 1rem;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  white-space: normal;
  -webkit-transition: border 0.15s linear;
  transition: border 0.15s linear;
  background-color: #01b6f5;
  border: 1px solid #01b6f5;
  color: #fff;
  text-align: center;
  display: block;
  width: 25%;
  margin: auto;
}

.react-rainbow-admin-user-detail_wrapper {
  overflow-y: auto;
}

.react-rainbow-admin-user-detail_breadcrumbs {
  padding: 20px 30px 0 30px;
}

.react-rainbow-admin-user-detail_cards-container {
  padding: 0 30px 30px 30px;
}

.react-rainbow-admin-user-detail_card {
  margin-top: 16px;
}

.react-rainbow-admin-user-detail_card-content {
  padding: 0.75rem 1rem;
}

.react-rainbow-admin-user-detail_icon {
  height: 25px;
  width: 25px;
  border-radius: 100%;
  background-color: #3b5998;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.react-rainbow-admin-user-detail_icon>svg {
  margin-right: 0 !important;
  color: white;
  fill: white;
}

.react-rainbow-admin-user-detail_user-name {
  color: #061c3f;
  font-size: 1.25rem;
  margin-right: 12px;
}

.react-rainbow-admin-user-detail_user-status {
  color: #1ad1a3;
}

.react-rainbow-admin-user-detail_vertical-divider {
  background-color: #e3e5ed;
  width: 1px;
  margin: 0 16px;
  height: 38px;
}

.react-rainbow-admin-user-detail_user-identity {
  display: flex;
  flex-wrap: wrap;
  border-top: 0.0625rem solid #e3e5ed;
}

.react-rainbow-admin-user-detail_user-identity_field {
  width: 50%;
  margin-bottom: 8px;
}

.react-rainbow-admin-user-detail_field {
  display: flex;
  flex-direction: column;
}

.react-rainbow-admin-user-detail_field-label {
  font-size: 0.75rem;
  color: #576574;
}

.react-rainbow-admin-user-detail_field-value {
  font-size: 1rem;
  color: #061c3f;
  width: 80%;
}

.fullwidth {
  width: 100%;
}

.sc-gqjmRU {
  width: 100%;

}

.rainbow-card_header {
  width: 100%;
}

.profile-picture {
  margin-bottom: 1rem;
  width: 10rem;
  height: 10rem;
  align-self: center;
}

.react-rainbow-admin-user-detail_orders {
  color: #061c3f;
  font-size: 20px;
  padding: 0 30px;
}

.react-rainbow-admin-user-details_table-container {
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  background-color: white;
  padding-bottom: 24px;
}

.react-rainbow-admin-user-details_table {
  border-top: 1px solid #e3e5ed;
  margin-top: 8px;
}

.profile-picture .sc-dxgOiQ{
  font-size: 3rem;
}
.jgSBRJ {
    text-transform: none;
}

.StripeElement {
    margin: 1.5rem auto;
    border: 1px solid #a4a7b5;
    border-radius: 12rem;
    padding: 0.75rem;
}

.checkout-telec-submit {
    display: block;
    margin: 1rem auto;
    width:  30%;
}


.phone-validation-message {
    color: #009acf;
    font-weight: bolder;
    margin: 1rem;
    margin-top: 0;
    font-size: 0.8rem;
}

.phone-validation-form {
    padding: 30px;
    line-height: normal;
}

.PhoneInputInput  {
    max-width: 30%;
}

.telecav-notice-elem {
    display: list-item;
    color: #009acf;
    margin-left: 1rem;
    margin-bottom: 5px;
  }
  
  .telecav-notice-elem > p {
    color: #3e3e3c;
  }


.add-telec-phone-step:hover { 
box-shadow: none;
}


.payment-card {
    box-shadow: none;
}

.payment-card:hover { 
    box-shadow: none;
}

.add-telec-modal {
    flex: 1 1;
    max-width: 80vw;
    width: inherit !important;
}

.requirements-list { 
    display: flex;
    flex-direction: column;
}
.checkout-telec-form {
    flex: 1 1;
    margin: auto 6rem;
}

.rainbow-time-picker_footer {
    width: 100%;
    display: flex;
    align-items: center;
}

.rainbow-radio-group_inner-container {
    display: flex;
    flex-direction: column;
}

.contacts-container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    flex: 1 1;
    margin: auto 2rem;
    align-items: center;
}


.PhoneInputCountryIconImg {
 vertical-align: unset !important;
 line-height: normal !important;
}

.starting-today-card {
    padding: 1rem;
    font-size: 1rem;
    border: none;
    border-radius: .25rem;
}

.starting-today-card > div > header > h2 > a > span {
    font-weight: 600;
    letter-spacing: 1px;
}

.starting-today-card > div > header > h2 > a  {
    text-decoration: none;
    cursor: default;
}

.starting-today-card > div {
margin-top: 0;
}

.dialogSpinner-container {
    display: flex;
    justify-content: center;
    min-height: 35vh;
    margin-top: 2rem;
}


.dialogSpinner-text {
    color: #03b2cb;
    font-size: 0.85rem;
}
.rbc-month-row {
    flex-basis: auto;
}

.rbc-event {
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 2px 5px;
    background-color: #1de9b6;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-size: 0.75rem;
}


.rbc-day-slot .rbc-event {
    border: none;
    display: flex;
    max-height: 100%;
    min-height: 20px;
    flex-flow: column wrap;
    align-items: flex-start;
    overflow: hidden;
    position: absolute;
}

.rbc-event.rbc-selected {
    background-color: #1de9b6;
}
.check-media-modal {
    padding: 1rem;
    width: auto;
}

.check-media-title {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    color: #009acf;
    font-size: 1.4rem;
}

.check-media-status-container {
    margin: 1rem 3rem;
    font-size: 1rem;
    margin-bottom: 2rem;
}

.check-media-status-item {
    margin-bottom: 1rem;
}

.check-media-status-item>* {
    margin-left: 10px;
}

.check-media-success {
    color: #1ad1a3;
}

.check-media-error {
    color: #ea4243;
}

.telec-start-modal {
    min-width: 80vw;
    min-height: 100vh;
    display: flex;
    flex: 1 1;
}

.telec-content-container {
    display: flex;
    height: 100vh;
    margin: 1rem auto;
}


.call-container {
    width: 70%;
}

.caller {
    height: 30vh;
    display: block;
    margin: 1rem auto;
}

.receiver {
    width: 100%;
    height: 60vh;
}


.chat-container {
    border-left: 2px solid #e6e9ef;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    flex: 1 1;
    margin-left: 0.375rem;
}

.messages-cont {
    max-height: 80%;
    min-height: 80%;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
    margin-top: 2rem;
    word-break: break-all;
    padding: 0 0.5rem;
}

.telec-msg-input {
    width: 100%;
}

.non-connected-remote {
    display: flex;
    justify-content: center;
    font-size: 2rem;
    padding: 2em;
}


.telec-icons-container {
    position: absolute;
    bottom: 4px;
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 1.5rem;
}
.email-verif-container {
    display: flex;
    justify-content: center;
    background: url('/assets/images/loginBG.jpg');
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: unset;
}


.react-rainbow-admin-forms_card {
    width: 40%;
    flex-wrap: wrap;
    position: absolute;
    opacity: 0.9;
}

.text-card {
    margin: 20px;
    padding: 30px;
}

.notif-icon {
    position: absolute;
}

.react-rainbow-admin_header {
    top: 0;
    left: 0;
    height: 68px;
    width: 100vw;
    z-index: 10000;
    border-bottom: 1px solid #f4f6f9;
}

.break-word {
    white-space: pre-line;
}

.react-rainbow-admin_header-logo {
    width: 130px;
    height: 40px;
}

.react-rainbow-admin_header-search {
    width: 280px;
}

.react-rainbow-admin_header-actions {
    justify-content: flex-end;
    flex-grow: 1;
}

.react-rainbow-admin_header--notification-icon-container {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: #e3e5ed;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.react-rainbow-admin_header--notification-icon {
    height: 12px;
}

.react-rainbow-admin_header-hamburger-button {
    display: none;
}

.react-rainbow-admin_header-github-icon {
    width: 32px;
    height: 32px;
    fill: #576574;
}

.react-rainbow-admin_header-github-icon:hover {
    fill: #061c3f;
}



.notif-container {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: flex-end;
}


@media (max-width: 600px) {
    .react-rainbow-admin_header {
        height: auto;
        padding: 16px 24px;
        align-items: flex-start;
        overflow-x: auto;
        margin-right: 0.7rem;
        width: auto;
    }

    .react-rainbow-admin_header-search {
        margin: 0;
        flex-shrink: 0;
        position: absolute;
        bottom: 12px;
        width: calc(100% - 80px);
    }

    .react-rainbow-admin_header-actions {
        align-items: flex-start;
    }

    .react-rainbow-admin_header-hamburger-button {
        display: inherit;
        height: 40px;
        width: 40px;
        margin-left: 16px;
    }

    .react-rainbow-admin_header-button-notification {
        right: 4px;
    }

    .react-rainbow-admin_header-github-icon {
        margin-right: 16px;
    }

    .notif-messages-icon {
        left: 24px;
    }

    .notif-messages-icon-text {
        left: -4px;
    }
}
.notif {
position: fixed;
text-align: center;
z-index: 1000001;
left: 50%;
bottom: 30px;
padding: 16px;
box-shadow: 1px 2px 2px 0px #01b6f5;
margin-left: -125px;
}
.logo-rotate {
    -webkit-animation: rotation 5s infinite linear;
            animation: rotation 5s infinite linear;
}

.no-internet-container {
display: flex;
justify-content: center;
margin-top: 7rem;
}


@-webkit-keyframes rotation {
from {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
to {
  -webkit-transform: rotate(359deg);
          transform: rotate(359deg);
}
}


@keyframes rotation {
from {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
to {
  -webkit-transform: rotate(359deg);
          transform: rotate(359deg);
}
}
.react-rainbow-admin-app_sidebar-container {
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 68px);
    margin-top: 68px;
    background-color: #fff;
    z-index: 99999;
    padding-bottom: 14px;
    padding-top: 14px;
}

.react-rainbow-admin-app_sidebar {
    width: 88px;
    height: 100%;
    border-right: 1px solid #f4f6f9;
    overflow-y: auto;
    flex-grow: 1;
}

.react-rainbow-admin-app_router-container {
    padding-top: 68px;
    padding-left: 88px;
    background-color: #f4f6f9;
    min-height: 100vh;
}

.react-rainbow-admin-app_sidebar-item {
    margin-bottom: 16px;
}

.react-rainbow-admin-app_sidebar-back-button-container {
    display: none;
}

@media (max-width: 600px) {
    .react-rainbow-admin-app_sidebar-container {
        height: 100vh;
        margin-top: 0;
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
        -webkit-transition: opacity 230ms linear, -webkit-transform 230ms linear;
        transition: opacity 230ms linear, -webkit-transform 230ms linear;
        transition: transform 230ms linear, opacity 230ms linear;
        transition: transform 230ms linear, opacity 230ms linear, -webkit-transform 230ms linear;
    }

    .react-rainbow-admin-app_router-container {
        padding-top: 116px;
        padding-left: 0;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
    }

    .react-rainbow-admin-app_sidebar-container--sidebar-hidden {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        -webkit-transition: opacity 250ms linear, -webkit-transform 250ms linear;
        transition: opacity 250ms linear, -webkit-transform 250ms linear;
        transition: transform 250ms linear, opacity 250ms linear;
        transition: transform 250ms linear, opacity 250ms linear, -webkit-transform 250ms linear;
    }

    .react-rainbow-admin-app_sidebar-back-button-container {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-grow: 2;
        align-items: flex-end;
        padding-top: 12px;
    }

    .react-rainbow-admin-app_sidebar-back-button-icon {
        color: #000;
    }

    .react-rainbow-admin-app_backdrop {
        display: unset;
        width: 100vw;
        height: 100vh;
        background-color: rgba(82, 95, 127, 0.4);
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
    }

    .patients-container { 
        max-width: 240vw;
    }
    
    .actions-container {
        justify-content: center;
    }
}

header > h2 {
    font-family: 'Jura', sans-serif!important;   
}

.is-jura {
    font-family: 'Jura', sans-serif!important;
}
.is-josefin {
    font-family: 'Josefin Slab', serif!important;
}


.variant-brand {
    background-color: inherit;
    color: #009acf;
    border: none;
}

.variant-brand:hover {
    /* border: 1px solid #009acf; */
    background-color: #d0ebf4a3;
    color: #009acf;
}

.variant-brand:active {
    /* border: 1px solid #009acf; */
    background-color: #d0ebf4a3;
    color: #009acf;
}
.variant-brand:focus {
    /* border: 1px solid #009acf; */
    background-color: #d0ebf4a3;
    color: #009acf;
}

.variant-success {
    background-color: inherit;
    color: #1ad1a3;
    border: none;
}

.variant-success:hover {
    /* border: 1px solid #1ad1a3; */
    background-color: #c1f0e482;
    color: #1ad1a3;
}

.variant-success:active {
    /* border: 1px solid #1ad1a3; */
    background-color: #c1f0e482;
    color: #1ad1a3;
}
.variant-success:focus {
    /* border: 1px solid #1ad1a3; */
    background-color: #c1f0e482;
    color: #1ad1a3;
}

.variant-destructive {
    background-color: inherit;
    color: #ea4243;
    border: none;
}

.variant-destructive:hover {
    /* border: 1px solid #ea4243; */
    background-color: #f4c9c975;
    color: #ea4243;
}

.variant-destructive:active {
    /* border: 1px solid #ea4243; */
    background-color: #f4c9c975;
    color: #ea4243;
}
.variant-destructive:focus {
    /* border: 1px solid #ea4243; */
    background-color: #f4c9c975;
    color: #ea4243;
}

.rainbow-tab_anchor-inner-text {
    white-space: normal;
}
