.react-rainbow-admin-app_sidebar-container {
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 68px);
    margin-top: 68px;
    background-color: #fff;
    z-index: 99999;
    padding-bottom: 14px;
    padding-top: 14px;
}

.react-rainbow-admin-app_sidebar {
    width: 88px;
    height: 100%;
    border-right: 1px solid #f4f6f9;
    overflow-y: auto;
    flex-grow: 1;
}

.react-rainbow-admin-app_router-container {
    padding-top: 68px;
    padding-left: 88px;
    background-color: #f4f6f9;
    min-height: 100vh;
}

.react-rainbow-admin-app_sidebar-item {
    margin-bottom: 16px;
}

.react-rainbow-admin-app_sidebar-back-button-container {
    display: none;
}

@media (max-width: 600px) {
    .react-rainbow-admin-app_sidebar-container {
        height: 100vh;
        margin-top: 0;
        transform: translateX(0%);
        transition: transform 230ms linear, opacity 230ms linear;
    }

    .react-rainbow-admin-app_router-container {
        padding-top: 116px;
        padding-left: 0;
        width: max-content;
    }

    .react-rainbow-admin-app_sidebar-container--sidebar-hidden {
        transform: translateX(-100%);
        transition: transform 250ms linear, opacity 250ms linear;
    }

    .react-rainbow-admin-app_sidebar-back-button-container {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-grow: 2;
        align-items: flex-end;
        padding-top: 12px;
    }

    .react-rainbow-admin-app_sidebar-back-button-icon {
        color: #000;
    }

    .react-rainbow-admin-app_backdrop {
        display: unset;
        width: 100vw;
        height: 100vh;
        background-color: rgba(82, 95, 127, 0.4);
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
    }

    .patients-container { 
        max-width: 240vw;
    }
    
    .actions-container {
        justify-content: center;
    }
}

header > h2 {
    font-family: 'Jura', sans-serif!important;   
}

.is-jura {
    font-family: 'Jura', sans-serif!important;
}
.is-josefin {
    font-family: 'Josefin Slab', serif!important;
}


.variant-brand {
    background-color: inherit;
    color: #009acf;
    border: none;
}

.variant-brand:hover {
    /* border: 1px solid #009acf; */
    background-color: #d0ebf4a3;
    color: #009acf;
}

.variant-brand:active {
    /* border: 1px solid #009acf; */
    background-color: #d0ebf4a3;
    color: #009acf;
}
.variant-brand:focus {
    /* border: 1px solid #009acf; */
    background-color: #d0ebf4a3;
    color: #009acf;
}

.variant-success {
    background-color: inherit;
    color: #1ad1a3;
    border: none;
}

.variant-success:hover {
    /* border: 1px solid #1ad1a3; */
    background-color: #c1f0e482;
    color: #1ad1a3;
}

.variant-success:active {
    /* border: 1px solid #1ad1a3; */
    background-color: #c1f0e482;
    color: #1ad1a3;
}
.variant-success:focus {
    /* border: 1px solid #1ad1a3; */
    background-color: #c1f0e482;
    color: #1ad1a3;
}

.variant-destructive {
    background-color: inherit;
    color: #ea4243;
    border: none;
}

.variant-destructive:hover {
    /* border: 1px solid #ea4243; */
    background-color: #f4c9c975;
    color: #ea4243;
}

.variant-destructive:active {
    /* border: 1px solid #ea4243; */
    background-color: #f4c9c975;
    color: #ea4243;
}
.variant-destructive:focus {
    /* border: 1px solid #ea4243; */
    background-color: #f4c9c975;
    color: #ea4243;
}

.rainbow-tab_anchor-inner-text {
    white-space: normal;
}