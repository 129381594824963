.rbc-month-row {
    flex-basis: auto;
}

.rbc-event {
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 2px 5px;
    background-color: #1de9b6;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-size: 0.75rem;
}


.rbc-day-slot .rbc-event {
    border: none;
    display: flex;
    max-height: 100%;
    min-height: 20px;
    flex-flow: column wrap;
    align-items: flex-start;
    overflow: hidden;
    position: absolute;
}

.rbc-event.rbc-selected {
    background-color: #1de9b6;
}