.container {
    position: relative;
    min-width: 70vw;

}

.rainbow-rainbow-forms_inputs-field {
    flex: 1;
    margin: 1em;
}

.hTXljT { 
    text-transform: none;
}


.form {
    width: 100%;
    flex-wrap: wrap;
    /* position: absolute; */
    margin-top: 4%;
    margin-bottom: 4%;
}

.react-rainbow-admin-forms_card:not(:last-child) {
    margin-right: 48px;
}

.react-rainbow-admin-forms_header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.react-rainbow-admin-forms_header h1 {
    font-size: 1.2rem;
    color: #061c3f;
}

.react-rainbow-admin-forms_header p {
    font-weight: 300;
    color: #576574;
    line-height: 1.71;
}

.react-rainbow-admin-forms_header p:nth-child(2) {
    font-weight: normal;
    color: #01b6f5;
    margin-left: 3px;
    cursor: pointer;
}

.rainbow-rainbow-forms_inputs-field {
    margin: 1rem;
}

.react-rainbow-admin-forms_logo {
    width: 140px;
    height: 50px;
    margin-bottom: 15px;
}

.rainbow-rainbow-forms_forgot {
    text-align: center;
    color: #01b6f5;
    font-size: 1rem;
}


.margin-datepicker {
    margin: 1rem;
}

@media (max-width: 800px) {
    .react-rainbow-admin-forms_container {
        padding: 16px;
    }

    .react-rainbow-admin-forms_section {
        flex-direction: column;
        padding-top: 0;
    }

    .rainbow-rainbow-forms_button {
        width: 100%;

        display: flex;
        justify-content: center;
    }

    .react-rainbow-admin-forms_section:last-child {
        width: 100%;
    }

    .react-rainbow-admin-forms_card {
        margin-top: 13px;
    }


    .rainbow-rainbow-forms_inputs-field {
        margin: 0 0 18px 0 !important;
    }

    .margin-datepicker {
        margin: 1rem auto;
    }
}