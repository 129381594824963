.add-index-c-button {
    height: max-content;
    align-self: end;
    margin: auto;
}

.wrapflex { 
    flex-wrap: wrap;
}

.card-cpurpose {
    opacity: 1;
    padding: 1.5rem;
}

.submit-buttons-cpurpose {
    justify-content: space-around;
}