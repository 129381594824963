.add-purpose-button { 
    margin: 20px 0;
    border-radius: .25rem;
}

.react-rainbow-admin-user-cell_container {
    display: flex;
    align-items: center;
}

.react-rainbow-admin-user-cell_name {
    margin-left: 8px;
    font-weight: 400;
}

.react-rainbow-admin-user-cell_container:hover {
    color: #01b6f5;
}

.button-action {
    margin-top: 5px;
    font-size: 0.8rem;
    line-height: 1.875rem;
}

.actions-container {
    display: flex;
    justify-content: flex-end;
}